<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
      الإعدادات
    </dashboard-page-title>
    <!--    {{storeActive}}-->
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="10">
            <div>
              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <h5 class="text-primary">قم باختيار الخدمات المقدمة</h5>
                </template>
                <div class="dashboard-custom-checkbox">
                  <b-form-checkbox-group
                    v-model="selected"
                    :options="options"
                    class="dashboard-custom-checkbox-inner  d-flex align-items-center flex-wrap"
                    value-field="item"
                    text-field="name"
                  ></b-form-checkbox-group>
                </div>
              </b-card>
              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <h5 class="text-primary">إعدادات الاستشارة</h5>
                </template>
                <div v-for="(item, index) in consultationSettings" :key="index" class="d-flex align-items-center gap1_2 mb-2">
                  <div class="consulation-title">
                    <h5 class="consulation-title-inner font-size-20">{{item.title}}</h5>
                    <h6 v-if="item.subtitle" class="consulation-subtitle-inner">{{item.subtitle}}</h6>
                  </div>
                  <b-form-radio-group
                    :id="`etectionMethod-${index}`"
                    v-model="item.selected"
                    :options="item.options"
                    :aria-describedby="item.title"
                    :name="`etectionMethod-${index}`"
                    class="detection-custom-radio consultation-radio d-flex align-items-center"
                  ></b-form-radio-group>
                </div>
              </b-card>
              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <h5 class="text-primary">الرسوم</h5>
                </template>
                <div class="d-flex align-items-center mb-4 gap_2 fees-container">
                  <h5 class="fees-main-title font-size-20">للعيادة</h5>
                  <div class="d-flex align-items-center gap_2">
                    <h5 class="fees-title-inner fees-subtitle-inner font-size-20">ستكون النسبة</h5>
                    <input-form
                      class="text-center m-0 clinic-value"
                      v-model="fees.clinic_fees"
                      validate="required"
                      name="feesOfClinic"
                    />
                    <div class="position-relative fees-note-wrapper">
                      <span>%</span>
                      <h5 class="fees-title-inner fees-subtitle-inner font-size-20">للعملية الواحدة</h5>
                    </div>
                    <h6 class="fees-subtitle-inner-note font-size-16">( كشف عيادة أو استشارة أو زيارة منزلية )</h6>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-4 gap_2 fees-container">
                  <h5 class="fees-main-title font-size-20">للطبيب المستقل</h5>
                  <div class="d-flex align-items-center gap_2">
                    <h5 class="fees-title-inner fees-subtitle-inner font-size-20">ستكون النسبة</h5>
                    <input-form
                      class="text-center m-0 doctor-value"
                      v-model="fees.individual_doctor_fees"
                      validate="required"
                      name="feesOfDoctor"
                    />
                    <div class="position-relative fees-note-wrapper">
                      <span>%</span>
                      <h5 class="fees-title-inner fees-subtitle-inner font-size-20">للعملية الواحدة</h5>
                    </div>
                    <h6 class="fees-subtitle-inner-note font-size-16">( كشف عيادة أو استشارة أو زيارة منزلية )</h6>
                  </div>
                </div>
              </b-card>
            </div>
            <div class="d-flex justify-content-center">
              <div class="d-flex justify-content-center">
                <b-button variant="primary" class="vete-save-btn m-auto" type="submit" v-if="!loadingButtonSubmit"> حفظ </b-button>
                <b-button variant="primary" class="vete-save-btn m-auto" v-else> <spinner-loading :text="'يتم التحميل'"></spinner-loading> </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import api from '../services'
export default {
  mounted () {
    core.index()
  },
  data () {
    return {
      loadingButtonSubmit: false,
      settings: null,
      selected: '',
      options: [
        { item: { clinic_examine: true }, name: 'كشف عيادة' },
        { item: { outdoor_examine: true }, name: 'زيارة منزلية' },
        { item: { consulting: true }, name: 'استشارة هاتفية' }
      ],
      consultationSettings: [
        {
          key: 'allow_video_call',
          title: 'السماح بمكالمة فيديو',
          options: [
            { text: 'نعم', value: true },
            { text: 'لا', value: false }
          ],
          selected: true
        },
        {
          key: 'allow_voice_call',
          title: 'السماح بمكالمة صوتية',
          options: [
            { text: 'نعم', value: true },
            { text: 'لا', value: false }
          ],
          selected: false
        },
        {
          key: 'allow_chatting',
          title: 'السماح بإجراء محادثة',
          options: [
            { text: 'نعم', value: true },
            { text: 'لا', value: false }
          ],
          selected: true
        },
        {
          key: 'allow_phone_call',
          title: 'السماح بعرض رقم الهاتف',
          options: [
            { text: 'نعم', value: true },
            { text: 'لا', value: false }
          ],
          selected: true
        },
        {
          key: 'allow_viewing_phone_number',
          title: 'السماح بالاتصال عن طريق الهاتف',
          subtitle: '( في حال السماح بعرض رقم الهاتف )',
          options: [
            { text: 'نعم', value: true },
            { text: 'لا', value: false }
          ],
          selected: false
        }
      ],
      fees: {
        clinic_fees: '',
        individual_doctor_fees: ''
      }
    }
  },
  methods: {
    getSettings () {
      api.getSettings().then(res => {
        console.log('response ->', res)
        this.settings = res.data
      })
    },
    onSubmit () {
      this.loadingButtonSubmit = true
      // handle consultation Settings
      let handleConsultSetting = {}
      this.consultationSettings.forEach(element => {
        handleConsultSetting = { ...handleConsultSetting, [element.key]: element.selected }
      })

      // handle provided_services
      let handleProvidedServices = {}
      this.selected.forEach(el => {
        handleProvidedServices = { ...handleProvidedServices, [Object.keys(el)[0]]: Object.values(el)[0] }
      })
      const findLast = ['clinic_examine', 'outdoor_examine', 'consulting'].filter(item => !Object.keys(handleProvidedServices).includes(item))
      handleProvidedServices = { ...handleProvidedServices, [findLast[0]]: false }

      const payload = {
        provided_services: handleProvidedServices,
        consulting_settings: handleConsultSetting,
        fees: this.fees
      }
      api.postSettings(payload).then(res => {
        // console.log(res, ' => response')
        core.showSnackbar('success', res.data.message)
      }).finally(() => {
        this.loadingButtonSubmit = false
      })
    }
  },
  created () {
    this.getSettings()
  },
  watch: {
    settings (val) {
      if (val.length) {
        for (const v of val) {
          if (v.key === 'provided_services') {
            this.selected = [
              { clinic_examine: Boolean(v.value.clinic_examine) },
              { consulting: Boolean(v.value.consulting) },
              { outdoor_examine: Boolean(v.value.outdoor_examine) }
            ]
          }
          if (v.key === 'consulting_settings') {
            for (const value of this.consultationSettings) {
              value.selected = v.value[value.key]
            }
          }
          if (v.key === 'fees') {
            // prices
            this.fees = {
              clinic_fees: v.value ? v.value.clinic_fees.toString() : '',
              individual_doctor_fees: v.value ? v.value.individual_doctor_fees.toString() : ''
            }
          }
        }
      } else {
        this.selected = [
          { clinic_examine: false },
          { consulting: false },
          { outdoor_examine: false }
        ]
      }
    }
  }
}
</script>
